#homeMainDiv {
    display: flex;
    flex-direction: column;
    /* margin-left: 25%; */
    width: 100%;
    /* background-image: url(../../assets/header-pattern.png); */
}

/* ############  COMMON HEADER  ################ */
.commonHeading {
    font-size: 4rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.commonHeading:first-child span {
    color: whitesmoke;
}

.commonHeading:nth-child(1) {
    color: var(--main-color);
}
/* ############  COMMON CAPTION  ################ */
.commonCaption {
    text-transform: uppercase;
}

.commonCaption:first-child p {
    color: whitesmoke;
    font-size: 1.5rem;
}

.commonCaption:nth-child(1) {
    color: var(--main-color);
    font-size: 1rem;
}

.commonPara {
    color: var(--secondary-color);
    font-size: large;
    line-height: 2rem;
}

/* #################################################################################################################### */

/* ==========MEDIA QUERIES (medium devices) ========= */
@media screen and (max-width:1024px) {
    #homeMainDiv {
        display: flex;
        margin-left: 0;
        width: 100%;
        /* background-color: pink; */
    }
}

/* ==========MEDIA QUERIES (small devices) ========= */
@media screen and (max-width:600px) {}