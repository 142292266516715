.EmpCardsWrapper {
    width: 100%;
}

#empSkeletonWrapper {
    padding: 1rem;
    margin-left: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    text-align: center; 
}

.empCardWrapperSemi {
    display: flex;
    flex-wrap: wrap;
}

.empSkeletonWrapperSemi {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
}

.empSkeletonWrapperSemi>img {
    width: 25rem;
    height: 30rem;
}

.empCaption{
    margin-top: 1rem;
}

.empCaption>p {
    color: var(--para-color);
    font-size: 1.5rem;
    font-weight: 100;
}

.empCaption>span {
    color: var(--main-color);
    font-size: 1.5rem;
    font-weight: 100;
}

/* ==========MEDIA QUERIES (medium devices) ========= */
@media screen and (max-width:1024px) {
    .empSkeletonWrapperSemi>img {
        height: 22rem;
        width: 18rem;
    }

    .empCaption>p {
        color: var(--para-color);
        font-size: 1.5rem;
    }

    .empCaption>span {
        color: var(--main-color);
        font-size: 1.5rem;
    }

    #empSkeletonWrapper {
        margin-left: 0%;        
    }
}

/* ==========MEDIA QUERIES (small devices) ========= */
@media screen and (max-width:600px) {}