.lists {
  background: rgb(177, 151, 119);
  background: linear-gradient(0deg, rgba(177, 151, 119, 1) 0%, rgba(167, 167, 167, 0.935) 100%);
  padding: 5rem;
  border-radius: 20px;
}

.lists>li {
  color: var(--para-color);
  font-size: 2rem;
  line-height: 5rem;
  list-style: square;
}

@media screen and (max-width:1024px) {
  .lists>li {
    font-size: 1rem;
    line-height: 3rem;
  }

  .lists {
    width: 100%;
    padding: 2rem;
  }
}