#aboutPageWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: rgb(39, 39, 39);
    background: linear-gradient(0deg, rgb(218, 218, 218) 0%, rgba(255, 255, 255, 0.823) 100%);
}

.bg-image {
    background-image: url(../../assets/appliance/uttara1.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    z-index: -1;
    position: fixed;
}


@media screen and (max-width:1024px) {
    #aboutPageWrapper {
        margin-left: 0;
        width: 100%;
    }

    .commonHeading {
        font-size: 3rem;
    }
}