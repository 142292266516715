#contactWrapper {
    padding-top: 15rem;
    width: 100%;
    /* margin-left: 25%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(39, 39, 39);
    background: linear-gradient(0deg, rgba(39, 39, 39, 1) 0%, rgba(39, 39, 39, 0.8229342762495624) 100%);
}

.bg-image {
    background-image: url(../../assets/1.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    z-index: -1;
    position: fixed;
}

@media screen and (max-width:1024px) {
    #contactWrapper {
        margin-left: 0;
        width: 100%;
    }
}