.listViewSkeletonWrapper {
    background-color: aliceblue;
    margin: 1rem;
    padding: 1rem;
    width: 15rem;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
}

.listViewSkeletonWrapper>p {
    /* color: var(); */
    font-size: larger;
}

/* #################      ICON      ############### */

.listViewSkeletonWrapper>i {
    font-size: 3rem;
}