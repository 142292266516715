.footerWrapper {
    background: rgb(176, 150, 118);
    background: linear-gradient(0deg, rgba(176, 150, 118, 1) 0%, rgb(234, 232, 232) 100%);

    display: flex;
  margin-top: 3rem;
}

.foot {
    width: 50%;
    margin-bottom: 2rem;
    font-size: 1rem;
}

.foot>ul>h2 {
    color: var(--main-color);
}

.foot>ul>li>p>span {
    color: var(--para-color);
}

.foot>ul>li>p {
    color: whitesmoke;
}

/* #################################################################################################################### */

/* ==========MEDIA QUERIES (medium devices) ========= */
@media screen and (max-width:1024px) {
 .footerWrapper{
    flex-direction: column;
    align-items: center;
   /* padding: 0; */
   width: 100%;
 }
 .foot{
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 0;
 }
}

/* ==========MEDIA QUERIES (small devices) ========= */
@media screen and (max-width:600px) {}