.nav{
    background-color: var(--main-bg);
    min-height: 100vh;
    width: 25%;
    display: flex;
    padding: 2rem;
    flex-direction: column;
    position: fixed;
    transition:.2s;
    z-index: 2;
    background-image: url(../../assets/header-pattern.png);
}
.nav-open{
    left: 0px;
}
.nav-closed{
    left: -255rem;
}
.navButton{
    position: absolute;
    position: fixed;
    z-index: 3;
    margin: 2rem;
    background-color: var(--main-color);
    padding: 1rem;
    border-radius: 100%;
    display: block;
}
#logo{
    color: var(--main-color);
    margin: 2rem auto;
}
#logo>img{
    width: 18rem;
}
.nav>ul>li>a {
    text-decoration: none;
    color: var( --para-color);
}
.nav>ul>li>a:hover {
    color: var( --main-color);
}
.nav>ul>li {
    list-style: none;
    color: var( --secondary-color);
    line-height: 4rem;
    letter-spacing: .4rem;
}

/* #################################################################################################################### */

/* ==========MEDIA QUERIES (medium devices) ========= */
@media screen and (max-width:1024px) {
    .nav{
        /* background-color: rgb(204, 89, 89); */
      width: 100%;
    }
    .navButton{
        display: block;
    }
}

/* ==========MEDIA QUERIES (small devices) ========= */
@media screen and (max-width:600px) {
    /* .nav{
        background-color: rgb(255, 0, 0);
       display: none;
    } */
    .navButton{
        display: block;
    }
}