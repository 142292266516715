#teamPageWrapper {
    display: flex;
    flex-direction: column;
    /* margin-left: 25%; */
    width: 100%;
    background: rgb(39,39,39);
    background: linear-gradient(0deg, rgb(218, 218, 218) 0%, rgba(255, 255, 255, 0.823) 100%);
}
@media screen and (max-width:1024px) {
    #teamPageWrapper  {
        width: 100%;
        margin-left: 0;
    }

}