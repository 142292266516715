  @import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

  * {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
  }

  html {
    scroll-behavior: smooth;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  :root {
    /* --main-bg: rgb(39, 39, 39); */
    --main-bg: rgb(237, 237, 237);
    --main-color: rgb(177, 151, 119);
    --secondary-color: rgb(153, 153, 153);
    --para-color: rgb(0, 0, 0);
  }

  body {
    background-color: var(--main-bg);
    font-family: 'Oswald', sans-serif;
  }

  .pad {
    padding: 0 4rem;
    padding-top: 10rem;
    /* background-color: aliceblue; */
  }

  /* ############  COMMON HEADER  ################ */
  .commonHeading {
    font-size: 4rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
    margin-left: 5rem;
  }

  .commonHeading:first-child span {
    color: var(--secondary-color)!important;
  }

  .commonHeading:nth-child(1) {
    color: var(--main-color);
  }

  /* ############  COMMON CAPTION  ################ */
  .commonCaption {
    text-transform: uppercase;
  }

  .commonCaption:first-child p {
    color: var(--secondary-color)!important;
    font-size: 1.5rem;
  }

  .commonCaption:nth-child(1) {
    color: var(--main-color);
    font-size: 1rem;
  }

  .commonPara {
    color: var(--para-color)!important;
    font-size: large;
    line-height: 2rem;
  }

  .marginTop {
    margin-top: 15rem;
  }


  /* ********************************************************* */

  /* MEDIA QUESRIES */

  /* ==========MEDIA QUERIES (medium devices) ========= */
  @media screen and (max-width:1024px) {

    .pad {
      padding: 6rem 2rem;
      /* background-color: aliceblue; */
    }

    /* ############  COMMON HEADER  ################ */
    .commonHeading {
      font-size: 4rem;
      text-transform: uppercase;
      margin-bottom: 1rem;
      margin-left: 1rem;
    }

    .commonHeading:first-child span {
      color: var(--secondary-color)!important;
    }

    .commonHeading:nth-child(1) {
      color: var(--main-color);
    }

    /* ############  COMMON CAPTION  ################ */
    .commonCaption {
      text-transform: uppercase;
    }

    .commonCaption:first-child p {
      color: var(--secondary-color)!important;
      font-size: 1.5rem;
    }

    .commonCaption:nth-child(1) {
      color: var(--main-color);
      font-size: 1rem;
    }

    .commonPara {
      color: var(--secondary-color);
      font-size: large;
      line-height: 2rem;
    }

    .marginTop {
      margin-top: 15rem;
    }

  }