#projectCardsWrapper {
    /* background-color: rgb(91, 91, 152); */
    position: relative;
    width: fit-content;
    transition: all 1s;
    margin: 1rem;
    /* padding: 2rem; */
}

#projectCardsWrapper>img {
    object-fit: contain;
    height: 20rem;
    padding: 1rem;
    /* width: 40rem; */
   
}

#projectCardsWrapper>img:hover {
    transition: ease-in-out .5s;
    transform: scale(.95);
}

.caption {
    position: absolute;
    background-color: var(--main-bg);
    transition: all 5s;
    display: none;
    z-index: 7;
    padding: 1rem;
}

#projectCardsWrapper:hover .caption {
    animation: hideMe 1s forwards;
    display: block;
    bottom: -1rem;
}

@keyframes hideMe {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}



@media screen and (max-width:1024px) {
    #projectCardsWrapper>img {
        height: 20rem;
        object-fit: cover;
        width: 20rem;
    }

}
