.owl-theme .item {
    width: 100%;
    height: 100vh;
}
 .item{
    width: 100%;
    height: 100vh;
 }
.item>h1 {
    color: whitesmoke;
    position: absolute;
    margin: 20rem 60rem;
    z-index: 2;
    font-size: 3rem;
    min-height: fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: 2rem;
    /* background-color: pink; */
}

.item>img {
    /* opacity: .6; */
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

/* #################################################################################################################### */

/* ==========MEDIA QUERIES (medium devices) ========= */
@media screen and (max-width:1024px) {
    .owl-theme .item {
        /* background-color: rgb(184, 157, 36); */
        width: 100%;
        height: 100vh;
    }

    .item>img {
        height: 100vh;
        object-fit: contain;
    }

    .item>h1 {
        /* color: rgb(209, 48, 48); */
        position: absolute;
        margin: 10rem 20rem;
        z-index: 2;
        font-size: 3rem;
        min-height: fit-content;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        letter-spacing: 2rem;
    }
}

/* ==========MEDIA QUERIES (small devices) ========= */
@media screen and (max-width:600px) {
    .item>h1 {
        color: white;
        position: absolute;
        margin: 7rem 2rem;
        z-index: 2;
        font-size: 1.5rem;
        min-height: fit-content;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        letter-spacing: 1rem;
    }
}