#intPageWrapper{
    display: flex;
    flex-direction: column;
    /* margin-left: 25%; */
    width: 100%;
    background: rgb(39,39,39);
    background: linear-gradient(0deg, rgb(218, 218, 218) 0%, rgba(255, 255, 255, 0.823) 100%);
}
.intPageWrapperSemi{
     display: flex;
     flex-wrap: wrap;

}
.intPageWrapperSemi>img{
height: 22rem;
padding: 1rem;
width: 40rem;
}
.bg-image {
    background-image: url(../../assets/images\ for\ website/low\ commercial/1.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    z-index: -1;
    position: fixed;
}
@media screen and (max-width:1024px) {
    #intPageWrapper {
        margin-left: 0;
        width: 100%;
    }
    .intPageWrapperSemi>img{
        height: auto;
        padding: 1rem;
        width: 21rem;
        }
}